import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function Canvas({setIsCanvasDrawn, setCanvasError, onSignatureDrawn}) {
  const {t} = useTranslation()
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [lastX, setLastX] = useState(0);
  const [lastY, setLastY] = useState(0);
  const [drawingPath, setDrawingPath] = useState([]);

  const handleMouseDown = (event) => {

    setIsDrawing(true);
    setLastX(event.nativeEvent.offsetX);
    setLastY(event.nativeEvent.offsetY);
    setDrawingPath([]);
    setIsCanvasDrawn(true)
    setCanvasError(false)
  };

  const handleMouseMove = (event) => {
    if (!isDrawing) return;
    const x = event.nativeEvent.offsetX;
    const y = event.nativeEvent.offsetY;

    const path = [lastX, lastY, x, y];
    setDrawingPath((prevPath) => [...prevPath, path]);

    draw();
    setLastX(x);
    setLastY(y);
  };
  const draw = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    
    // Clear the canvas before drawing
    //context.clearRect(0, 0, canvas.width, canvas.height);
  
    // Draw each segment of the path
    drawingPath.forEach((path) => {
      context.beginPath();
      context.moveTo(path[0], path[1]);
      context.lineTo(path[2], path[3]);
      context.strokeStyle = '#000'; // color
      context.lineWidth = 5; // width
      context.lineCap = 'round'; // style
      context.stroke();
    });
  };
  
  const handleMouseUp = () => {
    setIsDrawing(false);
    if (onSignatureDrawn) {
      const canvas = canvasRef.current;
      const signatureDataURL = canvas.toDataURL(); // Convert canvas to data URL
      onSignatureDrawn(signatureDataURL); // Pass signature data to parent component
    }
  };
  const handleResetCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    setIsCanvasDrawn(false);
    setDrawingPath([]);
    onSignatureDrawn(null)
  };
  return (
    <div style={{display:'inline-block', position:'relative'}}>
        <canvas 
          ref={canvasRef} 
          height={200}
          width={window.innerWidth>1000?570:300} 
          style={{ border: '1px solid #EFF0F7',boxShadow:'0px 2px 6px #13124212', borderRadius:'8px'}}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseOut={handleMouseUp}
          
        />
        <button type="button" onClick={handleResetCanvas} style={{position:'absolute',bottom:20,right:40, height:20,width:20, display:'flex', justifyContent:'center',alignItems:'center', border:'none',background:'inherit',color:'#D0004B', fontWeight:600, fontSize:'18px'}} >{t('Wissen')}</button>
        </div>
  );
}

export default Canvas;
