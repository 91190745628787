import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import MosqueStepOne from "./MosqueStepOne";
import MosqueStepTwo from "./MosqueStepTwo";
import MosqueStepThree from "./MosqueStepThree";
import { newMosqueOnboard } from "../../../customerAction";
import isEmail from "validator/lib/isEmail";
import { showNotification } from "../../../../../util/Utility";
import { isValidPhoneNumber } from "libphonenumber-js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { v4 as uuidv4 } from 'uuid';
import { setOnboardingDetails } from "../../../customerAction";
import { useTranslation } from "react-i18next";

const StepperContainer = styled.div`
  border-bottom: 1px solid #d9dbe9;
`;

const StepperContainerUi = styled.div`
  width: 100%;
  padding-bottom: 2rem;
  background: #fff;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Status = styled.div`
  width: 35px;
  height: 35px;
  font-weight: 700;
  font-size: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.active ? "#D0004B" : "#EFF0F6")};
  color: ${(props) => (props.active ? "#FFFFFF" : "#7E7E7E")};
  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
    font-size: 12px;
  }
`;

const Separator = styled.div`
  height: 6px;
  width: 30%;
  background: ${(props) => (props.active ? "#D0004B" : "#EFF0F6")};
  border-radius: 40px;
  margin: 0 10px;
  @media (max-width: 768px) {
    height: 3px;
  }
`;

function MosqueDetails({ defaultStep = 0 }) {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [isNext, setIsNext] = useState(false);
  const [isStepOne, setIsStepOne] = useState(false);
  const [isStepTwo, setIsStepTwo] = useState(false);
  const [isStepThree, setIsStepThree] = useState(false);
  const [isStepThreeErrors, setIsStepThreeErrors] = useState(true)
  const [isSubmittingStepThree, setIsSubmittingStepThree]= useState(false)
  const {t} = useTranslation()
  const formRef = React.useRef(null);
  useEffect(() => {
    if (defaultStep) {
      setCurrent(defaultStep);
    }
  }, [defaultStep]);

  const onboardingDetails = useSelector(
    (state) => state.customerReducer.onboardingDetails
  );
  const is_onboarded = useSelector(
    (state) => state.customerReducer.is_onboarded
  );
  const is_onboarding = useSelector(
    (state) => state.customerReducer.is_onboarding
  );

  const stepOneValidation = () => {
    return (
      !!onboardingDetails?.entity_name.trim() &&
      !!onboardingDetails?.entity_address.trim() &&
      !!onboardingDetails?.entity_pincode.trim() &&
      !!onboardingDetails?.entity_country.trim() &&
      !!onboardingDetails?.entity_city.trim()
    );
  };

  const stepTwoValidation = () => {
    return (
      !!onboardingDetails?.contact_name.trim() &&
      !!onboardingDetails?.contact_email.trim() &&
      !!isEmail(onboardingDetails?.contact_email.trim()) &&
      !!onboardingDetails?.contact_phone.trim() &&
      isValidPhoneNumber(
        onboardingDetails?.contact_phone,
        onboardingDetails?.country_abbreviation
      )
    );
  };

  const stepThreeValidation = () => {
    return !isStepThreeErrors;
  };

  useEffect(() => {
    if (is_onboarded) {
      setIsStepThree(true);
    }
  }, [is_onboarded]);

  useEffect(() => {
    if (current === 0) {
      setIsNext(stepOneValidation());
    }
    if (current === 1) {
      setIsNext(stepTwoValidation());
    }
    if (current === 2) {
      setIsNext(stepThreeValidation());
    }
  }, [current, onboardingDetails, isStepThreeErrors]);
  useEffect(() => {
    stepThreeValidation();
  }, [isStepThreeErrors]);

  const renderComponent = () => {
    switch (current) {
      case 0:
        return <MosqueStepOne />;

      case 1:
        return <MosqueStepTwo />;

      case 2:
        return (
          <MosqueStepThree
            ref={formRef}
            setIsStepThreeErrors={setIsStepThreeErrors}
          />
        );

      default:
        return null;
    }
  };
  const printDocument = async () => {
    const form = document.querySelector(".stepThreeForm");
    console.log(form);
    if (!form) return;
    form.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
    setTimeout(() => {
      html2canvas(form, {
        scrollY: -window.scrollY,
        width: form.scrollWidth,
        height: form.scrollHeight,
      }).then(async (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const aspectRatio = canvas.width / canvas.height;
        const pdfImgHeight = pdfHeight;
        const pdfImgWidth = pdfImgHeight * aspectRatio;
        const offsetX = (pdfWidth - pdfImgWidth) / 2;
        pdf.addImage(imgData, "JPEG", offsetX, 0, pdfImgWidth, pdfImgHeight);
        // pdf.save("download.pdf");
        const pdfBytes = pdf.output("arraybuffer");
        // const pdfBuffer = Buffer.from(pdfBytes);
        const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
        const formData = new FormData();
        formData.append("pdf", pdfBlob, `${uuidv4()}.pdf`);
        const baseUrl = process.env.REACT_APP_STAGE==='development'?'apidev.mosqapp.com':'api.mosqapp.com'
        fetch(`https://${baseUrl}/file/upload`, {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to upload PDF");
            }
            return response.json();
          })
          .then((data) => {
            setIsSubmittingStepThree(false)
            console.log("PDF uploaded successfully:", data.data?.link);
            dispatch(setOnboardingDetails('pdf_registration_link', data.data?.link))
            dispatch(newMosqueOnboard({ onboardEntityType: "REGISTER" }));
          })
          .catch((error) => {
            console.error("Error uploading PDF:", error);
          });
      });
    }, 1000);
  };

  const handleSubmit = () => {
    if (!!onboardingDetails?.contact_phone.trim()) {
        setIsSubmittingStepThree(true)
      printDocument();
      
      //dispatch(newMosqueOnboard({ onboardEntityType: "REGISTER" }));
    } else {
        setIsSubmittingStepThree(true)
      printDocument();
    //   dispatch(newMosqueOnboard({ onboardEntityType: "REGISTER" }));
    }
    const categoryElement = document.getElementById(`onboarding-state`);
    if (categoryElement && categoryElement.scrollIntoView) {
      categoryElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const next = (step) => {
    setCurrent(current + 1);
    setIsNext(false);
    if (step === 0) {
      setIsStepOne(true);
    }
    if (step === 1) {
      setIsStepTwo(true);
    }
    if (step === 2) {
      setIsStepThree(true);
    }
  };

  const prev = (step) => {
    setCurrent(current - 1);
    if (step === 1) {
      setIsStepOne(false);
      setIsStepTwo(false);
      setIsStepThree(false);
    }
    if (step === 2) {
      setIsStepOne(true);
      setIsStepTwo(false);
      setIsStepThree(false);
    }
    const categoryElement = document.getElementById(`onboarding-state`);
    categoryElement.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <StepperContainer>
        {/*<OnboardingSteps />*/}

        <StepperContainerUi>
          <Step>
            <Status active={current === 0 || isStepOne}>1</Status>
          </Step>
          <Separator active={isStepOne} />
          <Step>
            <Status active={current === 1 || isStepTwo}>2</Status>
          </Step>
          <Separator active={isStepTwo} />
          <Step>
            <Status active={current === 2}>3</Status>
          </Step>
        </StepperContainerUi>
      </StepperContainer>

      <div>{renderComponent()}</div>

      <div className="d-flex justify-content-between flex-row-reverse align-items-baseline">
        {current < 2 && (
          <div className="d-flex justify-content-end" style={{ width: "50%" }}>
            {isNext ? (
              <button className="fill-bt" onClick={() => next(current)}>
                {t('Volgende Stap')}
              </button>
            ) : (
              <button className="gray-bt" disabled>
                {t('Volgende Stap')}
              </button>
            )}
          </div>
        )}
        <div
          className="d-flex justify-content-between"
          style={
            !is_onboarded && current === 2
              ? { width: "100%" }
              : { width: "50%" }
          }
        >
          {!is_onboarded && current > 0 && (
            <button
              className="prev-bt"
              style={{ margin: "0 8px" }}
              onClick={() => prev(current)}
            >
              {t('Vorige stap')}
            </button>
          )}
          {!is_onboarded &&
            current === 2 &&
            ((isNext && !isSubmittingStepThree) ? (
              <button
                type="button"
                className="fill-bt"
                onClick={() => handleSubmit()}
              >
                {is_onboarding ? "Indienen" : "Indienen"}
              </button>
            ) : (
              <button type="button" className="gray-bt" disabled>
                {t('Indienen')}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
}

export default MosqueDetails;
