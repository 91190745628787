import React from "react";
import { useSelector } from "react-redux";
import Label from "../../../../../components/Label";
import BarChart from "../../../../../components/D3Charts/BarChart";
import { useTranslation } from "react-i18next";
const PlanMembers = () => {
  const dashboardStats = useSelector(state => state.adminReducer?.dashboardStats);

  const getData = () => {
    const data = [];
    if (dashboardStats?.top_mosques_by_revenue?.length > 0) {
      dashboardStats?.top_mosques_by_revenue.forEach(element => {
        data.push(element?.name);
      });
    }
    return data;
  }
  const {t} = useTranslation()
  const getCategories = () => {
    const data = [];
    if (dashboardStats?.top_mosques_by_revenue?.length > 0) {
      dashboardStats?.top_mosques_by_revenue.forEach(element => {
        data.push(element?.current_month_payment);
      });
    }
    return data;
  }

  return (
    <div className="overview-card"  style={{width: "100%", marginTop:"20px", marginBottom: "20px"}}>
      <Label variant="text1">{t('Overzicht')}</Label>
      <Label variant="text5" color="#7E7E7E">{t('Bedrag per moskee')}</Label>
      <div className="white_box" style={{ height: "100%"}}>
        <div className="" style={{ height: "100%" }}>
          <div style={{height : "100%"}}>
            <BarChart
              xdim={780}
              ydim={400}
              margin={{ top: 0, bottom: 60, left: 0, right: 0 }}
              name="Amount"
              data={getCategories()}
              categories={getData()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanMembers;
