import React, {useEffect, useState} from "react";
import PublishedBlog from "./PublishedBlog";
import PublishBlog from "./PublishBlog";
import Box from "../../../../../components/Box";
import {useParams, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";

const BLOG_LIST_SCREEN = 'blog-list';
const BLOG_NEW_SCREEN = 'new-list';
const BLOG_UPDATE_SCREEN = 'update-screen';

function BlogTab() {
    const {blog_id, url} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation()
    const [selectedSegment, setselectedSegment] = useState(BLOG_LIST_SCREEN);
    const [showComponent, setShowComponent] = useState("initial");

    const edit_blog = useSelector(state => state.mosqueReducer.edit_blog);
    const activeEntity = useSelector(state => state.mosqueReducer.activeEntity);

    useEffect(() => {
        if (blog_id) {
            setselectedSegment(BLOG_UPDATE_SCREEN)
        }
    }, [blog_id])

    const setSegment = (selectedSegment) => {
        setselectedSegment(selectedSegment);
    };

    let dataList = [
        {label: "Gepubliceerde nieuwsberichten", link: `/mosque/${activeEntity._id}/blogs`, isBold: true, color: "#222"},
        {label: `Gepubliceerde nieuwsberichten`, link: ``, isBack: true, isBold: true, color: "#222"},
        {label: `Bewerk nieuwsbericht`, link: "", isBold: true, color: "#7E7E7E"},
    ];

    const handleBlogHome = () => {
        if (blog_id) {
            navigate(`/mosque/${activeEntity._id}/blogs`);
        }
        setSegment(BLOG_LIST_SCREEN);
    }

    return (
        <div>
            <div className="d-flex justify-content-between" style={{paddingLeft:"2rem", paddingRight:"2rem"}}>
                {
                    selectedSegment === BLOG_LIST_SCREEN ?
                        <Box
                            fontSize="20px"
                            fontWeight={400}>
                            {showComponent !== "" && t("Gepubliceerde nieuwsberichten")}
                        </Box>
                        :
                        <div>
                            {selectedSegment === BLOG_NEW_SCREEN ?
                                <Box
                                    fontSize="20px"
                                    fontWeight={400}>
                                    {t('Publiceer nieuwsbericht')}
                                </Box>
                            :
                                <Breadcrumbs
                                    dataList={dataList}
                                />
                            }
                        </div>
                }
                <ul className="d-flex plan-modal">
                    <li
                        className="information col-6"
                        style={{
                            cursor: "pointer",
                            fontWeight: selectedSegment === BLOG_LIST_SCREEN ? "600" : "600",
                            backgroundColor:
                                selectedSegment === BLOG_LIST_SCREEN ? "#D0004B" : "#fff",
                            color: selectedSegment === BLOG_LIST_SCREEN ? "#fff" : "#D0004B",
                            borderRadius:
                                selectedSegment === BLOG_LIST_SCREEN ? "50px 0px 0px 50px" : "50px 0px 0px 50px",
                        }}
                        onClick={() => handleBlogHome()}
                    >
                        {t('Gepubliceerde')}<br />{t('nieuwsberichten')}
                    </li>
                    <li
                        className="screen-details col-6"
                        style={{
                            cursor: "pointer",
                            fontWeight: selectedSegment === BLOG_NEW_SCREEN || selectedSegment === BLOG_UPDATE_SCREEN ? "600" : "600",
                            backgroundColor:
                                selectedSegment === BLOG_NEW_SCREEN || selectedSegment === BLOG_UPDATE_SCREEN ? "#D0004B" : "#fff",
                            color: selectedSegment === BLOG_NEW_SCREEN || selectedSegment === BLOG_UPDATE_SCREEN ? "#fff" : "#D0004B",
                            borderRadius:
                                selectedSegment === BLOG_NEW_SCREEN || selectedSegment === BLOG_UPDATE_SCREEN ? "0px 50px 50px 0px" : "0px 50px 50px 0px",
                        }}
                        onClick={() => setSegment(BLOG_NEW_SCREEN)}
                    >
                        {t('Publiceer')}<br />{t('nieuwsbericht')}{" "}
                    </li>
                </ul>
            </div>
            <div>
                {selectedSegment === BLOG_LIST_SCREEN ? (
                    <PublishedBlog setSegment={setSegment}/>
                ) : null}
                {selectedSegment === BLOG_NEW_SCREEN || selectedSegment === BLOG_UPDATE_SCREEN ? (
                    <PublishBlog selectedSegment={selectedSegment} setSegment={setSegment}/>
                ) : null}
            </div>
        </div>
    );
}

export default BlogTab;
