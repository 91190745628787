import React, { useEffect, useState } from "react";
import Table from "../../../../components/Table/Table";
import Box from "../../../../components/Box";
import Label from "../../../../components/Label";
import Searchbar from "../../../../components/Searchbar";
import links from "../../../../constants/industry.json";
import DefaultModal from "../../../../components/DefaultModal/DefaultModal";
import Icon from "../../../../components/Icon";
import EditMosque from "./components/EditMosque";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEntityFinRevenue,
  fetchMosqueList,
  updateEntityFinRevenue,
  updateMosque,
  updateMosqueStatus,
  updatePage,
} from "../../adminAction";
import moment from "moment";
import RevenueGraphModal from "./components/RevenueGraphModal";
import PaginationV2 from "../../../../components/Pagination/PaginationV2";
import truncate from "lodash/truncate";
import styled from "styled-components";
import CheckBoxSortBy from "./components/CheckBoxSortBy";
import CheckBoxDateRange from "./components/CheckBoxDateRange";
import BulkUploadModal from "../../../../components/BulkUpload/BulkUploadModal";
import { fetchPlanList } from "../../../Mosque/mosqueAction";
import { useTranslation } from "react-i18next";
import { background } from "styled-system";

const TemplateTableData = [
  {
    heading: "S.No.",
  },
  {
    heading: "Naam",
  },
  {
    heading: (
      <>
        {" "}
        &nbsp;&nbsp;Aantal <br /> gebruikers
      </>
    ),
  },
  {
    heading: (
      <>
        Behaalde <br /> Inkomsten
      </>
    ),
  },
  {
    heading: (
      <>
        Inkomsten in
        <br /> behandeling
      </>
    ),
  },
  {
    heading: "Status",
  },
  {
    heading: <> Bewerken</>,
  },
  {
    heading: <> &nbsp;Grafiek</>,
  },
  {
    heading: "Actie",
  },
  {
    heading: "Downloaden",
  },
];

const BoxContainer = styled(Box)`
  background: #f5f5f5;
  width: 160px;
  padding: 10px;
  border-radius: 8px;
  margin-left: 10px;
  height: 38px;
`;

const MosqueList = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const bizIndustryLinks = links;
  const [show, setshow] = useState(false);
  const [editMosque, setEditMosque] = useState();
  const [selectedMosque, setSelectedMosque] = useState();
  const [isGraphShow, setIsGraphShow] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isOpenBulkUploadModal, setOpenBulkUploadModal] = useState(false);
  const [selectedMosqueIdForBulkUpload, setMosqueForBulkUpload] =
    useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const {t} = useTranslation()
  const [sortBy, setSortBy] = useState("");
  const [dateRange, setDateRange] = useState("30");

  const mosqueList = useSelector(
    (state) => state.adminReducer.mosque?.mosqueList
  );
  const mosqueCount = useSelector(
    (state) => state.adminReducer.mosque?.mosqueCount
  );
  const currentPage = useSelector(
    (state) => state.adminReducer.mosque?.currentPage
  );
  const pageSize = useSelector((state) => state.adminReducer.mosque?.pageSize);
  const [templateTableData, setTemplatetableData] = useState([
    {
      heading: "S.No.",
    },
    {
      heading: t("Naam"),
    },
    {
      heading: (
        <>
          {" "}
          &nbsp;&nbsp;{t('Aantal')} <br /> {t('gebruikers')}
        </>
      ),
    },
    {
      heading: (
        <>
          {t('Behaalde')} <br /> {t('Inkomsten')}
        </>
      ),
    },
    {
      heading: (
        <>
          {t('Inkomsten in')}
          <br /> {t('behandeling')}
        </>
      ),
    },
    {
      heading: "Status",
    },
    {
      heading: <> {t('Bewerken')}</>,
    },
    {
      heading: <> &nbsp;{t('Grafiek')}</>,
    },
    {
      heading: t("Actie"),
    },
    {
      heading: t("Downloaden"),
    },
  ])

  useEffect(() => {
    dispatch(fetchMosqueList());
  }, [dispatch]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const filteredDetails = {};
      filteredDetails.qtext = value;
      dispatch(fetchMosqueList(filteredDetails));
    }
  };

  const handleButton = (val) => {
    if (val === "") {
      const filteredDetails = {};
      dispatch(fetchMosqueList(filteredDetails));
    }
    setValue(val);
  };

  const onPageChange = (page) => {
    dispatch(updatePage(page));
    dispatch(fetchMosqueList());
  };

  const disableMember = (item) => {
    setSelectedId(item);
    if (item?.is_active) {
      setIsDisable(!isDisable);
    } else {
      updateStatus(item);
    }
  };

  const updateStatus = (item) => {
    let data = {};
    if (item) {
      data = { ...item };
    } else {
      data = { ...selectedId };
    }
    dispatch(updateMosqueStatus(data?._id, { is_active: !data?.is_active }));
  };

  const updateData = () => {
    setSelectedId(null);
    setIsDisable(!isDisable);
  };

  const handleRenderGraph = (id) => {
    setIsGraphShow(true);
    let start_date = moment()
      .startOf("year")
      .subtract(1, "years")
      .toISOString();
    let end_date = moment().endOf("year").toISOString();
    dispatch(fetchEntityFinRevenue(id, start_date, end_date));
  };

  const handleBulkUpload = (id) => {
    console.log(id);
    setMosqueForBulkUpload(id);
    dispatch(fetchPlanList(id));
    setOpenBulkUploadModal(true);
  };

  const handleRevenueGraphModal = () => {
    setIsGraphShow(false);
    dispatch(updateEntityFinRevenue([]));
  };
  
  const renderMosqueList = () => {
    console.log("mosque list :", mosqueList);
    return mosqueList?.map((item, index) => {
      return [
        `${(currentPage - 1) * pageSize + index + 1}.`,
        <div style={{ display: "block" }} onClick={() => setshow(true)}>
          <h6
            style={{ fontWeight: "600" }}
            title={item?.name || "NA"}
            onMouseOver={(e) => (e.target.title = item?.name)}
            onMouseOut={(e) =>
              (e.target.title = item?.name
                ? truncate(item?.name, { length: 15 })
                : "NA")
            }
          >
            {item?.name ? truncate(item?.name, { length: 20 }) : "NA"}
          </h6>
          <div>
            <small
              style={{
                color: "#4763E4",
                background: "#E3F2FD",
                borderRadius: "4px",
              }}
            >
              {item?.organisation_id?.saas_subscription_payment_cycle}
            </small>
            <small
              style={{
                color: "#00AC4F",
                background: "#E5F7ED",
                borderRadius: "4px",
                marginLeft: "8px",
              }}
            >
              {item?.organisation_id?.saas_payment_method}
            </small>
          </div>
        </div>,
        <Box ml="20px">
          <b className="b-revenue-size">
            {!item?.total_member_count ? "-" : item?.total_member_count}
          </b>
        </Box>,
        <Box ml="20px">
          <b className="b-revenue-size">
            {!item?.current_month_revenue
              ? "0"
              : `${item?.current_month_revenue}`}
          </b>
        </Box>,
        <Box ml="20px">
          <b className="b-revenue-size">
            {!item?.pending_revenue ? "0" : `${item?.pending_revenue}`}
          </b>
        </Box>,
        <div className="tableCheckbox">
          <label className="switch">
            <input
              type="checkbox"
              checked={item.is_active}
              onClick={() => disableMember(item)}
            />
            <span className="slider round"></span>
          </label>
        </div>,
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setSelectedMosque(item)}
        >
          <Icon iconName="tableEdit" />
        </div>,
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRenderGraph(item?._id)}
        >
          <Icon iconName="tableGraph" />
        </div>,
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleBulkUpload(item?._id)}
        >
          <Icon iconName="WmessageQuestion" />
        </div>,
        <div
          style={{
            cursor: "pointer",
            border: "1px solid #D0004B",
            borderRadius: "16px",
            fontSize: "10px",
            width: "100%",
            fontWeight: "700",
            pointerEvents:item.pdf_registration_link?'':'none'
          }}
          
        >
          <a
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              color: "#D0004B",
            }}
            href={`https://${item?.pdf_registration_link}` || ''}
            download="download.pdf"
            target="_blank"
            rel="noreferrer"
            id="pdfDownloadLink"
          >
            <Icon iconName="download" />
            PDF
          </a>
        </div>,
      ];
    });
  };

  const handleSortBy = (selection) => {
    let updatedFilters = {};
    setSortBy(selection);
    if (selection) {
      updatedFilters.sortBy = selection;
    }
    dispatch(fetchMosqueList(updatedFilters));
  };

  const handleDateRange = (selection) => {
    let updatedFilters = {};
    setDateRange(selection);
    if (selection === "30") {
      updatedFilters.start_date = moment()
        .startOf("day")
        .subtract({ day: 30 })
        .toISOString();
      updatedFilters.end_date = moment().endOf("day").toISOString();
    }
    if (selection === "15") {
      updatedFilters.start_date = moment()
        .startOf("day")
        .subtract({ day: 15 })
        .toISOString();
      updatedFilters.end_date = moment().endOf("day").toISOString();
    }
    if (selection === "7") {
      updatedFilters.start_date = moment()
        .startOf("day")
        .subtract({ day: 7 })
        .toISOString();
      updatedFilters.end_date = moment().endOf("day").toISOString();
    }
    if (selection === "") {
      updatedFilters = {};
    }
    dispatch(fetchMosqueList(updatedFilters));
  };

  return (
    <>
      <div className="table-bg pb-4 mosque-info-box">
        {selectedMosque ? (
          <EditMosque
            item={selectedMosque}
            setSelectedMosque={setSelectedMosque}
          />
        ) : (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px="20px"
              py="24px"
              pb="7px"
            >
              <Label fontSize="20px" fontWeight={700}>
                {t('Moskee informatie')}
              </Label>
              <Box className="d-flex">
                <Searchbar
                  value={value}
                  setValue={(e) => handleButton(e.target.value)}
                  placeholder={t("Zoeken")}
                  fontSize="12px"
                  border="0px solid rgba(0,0,0,.08)"
                  searchIcon
                  backgroundColor="#F5F5F5"
                  color="#000"
                  borderRadius="10px"
                  width="252px"
                  height="38px"
                  paddingLeft="25px"
                  position="relative"
                  right="20px"
                  inputProps={{
                    type: "search",
                    onKeyPress: handleKeyPress,
                    height: "20px",
                    marginBottom: "3px",
                  }}
                />
                <BoxContainer>
                  <CheckBoxSortBy value={sortBy} handleSortBy={handleSortBy} />
                </BoxContainer>
                <BoxContainer>
                  <CheckBoxDateRange
                    value={dateRange}
                    handleSortBy={handleDateRange}
                  />
                </BoxContainer>
              </Box>
            </Box>
            <Table
              gridTemplateColumnsHeader="6% 20% 10% 10% 12% 10% 7% 7% 7% 11%"
              gridTemplateColumnsBody="6% 20% 10% 10% 12% 10% 7% 7% 7% 11%"
              columns={templateTableData}
              data={renderMosqueList()}
            />
          </>
        )}
        <DefaultModal
          show={isGraphShow}
          onHide={() => handleRevenueGraphModal()}
          className="congrats-modal revenue-graph"
          style={{ paddingRight: "0px" }}
          centered={true}
          showHeader={false}
          size="lg"
          showFooter={false}
        >
          <RevenueGraphModal onHide={() => handleRevenueGraphModal()} />
        </DefaultModal>
        <DefaultModal
          show={isDisable}
          onHide={() => updateData()}
          className="congrats-modal"
          style={{ paddingRight: "0px" }}
          centered={true}
          showHeader={false}
          size=""
          showFooter={false}
        >
          <div className="d-flex justify-content-between">
            <div></div>
            <div onClick={() => updateData()}>
              <Icon iconName="blackCross" />
            </div>
          </div>
          <div className="congrats-screen">
            <img src="https://ik.imagekit.io/rzlzf40okya/elixir/diseble.svg" />
            <Box mt="15px">
              <h4>{t('Disable Mosque')}</h4>
            </Box>
            <Label color="#7E7E7E">
              {t("You will loose this mosque’s details and payment history")}
            </Label>

            <div className="d-flex justify-content-around mt-3">
              <button className="prev-bt" onClick={() => updateData()}>
                {t('Cancel')}
              </button>
              <button
                className="fill-bt"
                onClick={() => {
                  updateStatus();
                  updateData();
                }}
              >
                {t('Disable Mosque')}
              </button>
            </div>
          </div>
        </DefaultModal>
      </div>
      {!selectedMosque ? (
        <PaginationV2
          currentPage={currentPage}
          pageSize={pageSize}
          totalCount={mosqueCount}
          onPageChange={onPageChange}
        />
      ) : null}
      {isOpenBulkUploadModal ? (
        <BulkUploadModal
          show={isOpenBulkUploadModal}
          onHide={() => setOpenBulkUploadModal(false)}
          activeMosqueId={selectedMosqueIdForBulkUpload}
        />
      ) : null}
    </>
  );
};

export default MosqueList;
