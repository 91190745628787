import React, { useState } from "react";
import Label from "../../../../../components/Label";
import Box from "../../../../../components/Box";
import Button from "../../../../../components/Buttons";
import { useDispatch } from "react-redux";
import {
  updateMosque,
  updateMosqueAdminDetails,
} from "../../../adminAction";
import ErrorMessage from "../../../../../components/ErrorMessage";
import { getFormattedTime } from "../../../../../util/timeUtility";
import Input from "../../../../../components/Input";
import isNumeric from "validator/es/lib/isNumeric";
import { useTranslation } from "react-i18next";

const EditMosque = ({ item, setSelectedMosque }) => {
  const dispatch = useDispatch();
  const [mosqueDetails, setMosqueDetails] = useState(JSON.parse(JSON.stringify(item)));
  const [isNext, setIsNext] = useState(false);
  const [errors, setErrors] = useState({});
  const {t} = useTranslation()

  const handleUpdate = (keyName, keyValue) => {
    setIsNext(true);
    let update = { ...mosqueDetails };
    if (keyName === "contact_name") {
      update["primary_user_id"]["contact_name"] = keyValue;
    } else {
      update[keyName] = keyValue;
    }
    setMosqueDetails(update);
  };

  const handleValidation = () => {
    let isValid = true;
    console.log(mosqueDetails);
    let errors = {};
    if (!mosqueDetails.name) {
      isValid = false;
      errors["name"] = "Please enter name";
    }
    if (!mosqueDetails.admin_fee || !isNumeric(mosqueDetails.admin_fee.toString())) {
      isValid = false;
      errors["admin_fee"] = "Please enter valid admin fee";
    }
    if (!mosqueDetails.entity_merchant_id) {
      isValid = false;
      errors["entity_merchant_id"] = "Please enter valid merchant id";
    }
    if (!mosqueDetails.entity_merchant_key) {
      isValid = false;
      errors["entity_merchant_key"] = "Please enter valid merchant key";
    }
    if (!mosqueDetails?.primary_user_id?.contact_name) {
      isValid = false;
      errors["contact_name"] = "Please enter admin name";
    }
    if(!mosqueDetails?.entity_merchant_id){
      isValid = false;
      errors["entity_merchant_id"] = "Please enter merchant_id";
    }
    if(!mosqueDetails?.entity_merchant_key){
      isValid = false;
      errors["entity_merchant_key"] = "Please enter merchant_key";
    }
    return {
      isValid,
      errors,
    };
  }

  const handleUpdateButton = () => {
    setErrors({});
    let validate = handleValidation();
    if (validate.isValid) {
      let details = { ...mosqueDetails };
      const mosque = {};
      mosque.name = details?.name.trim();
      mosque._id = details?._id;
      if (details?.admin_fee) {
        mosque.admin_fee = details?.admin_fee;
      }
      if (details?.entity_merchant_id) {
        mosque.entity_merchant_id = details?.entity_merchant_id;
      }
      if (details?.entity_merchant_key) {
        mosque.entity_merchant_key = details?.entity_merchant_key;
      }
      const admin = {};
      admin.contact_name = details?.primary_user_id?.contact_name.trim();
      admin._id = details?.primary_user_id?._id;
      if (mosque?._id && mosque) {
        dispatch(updateMosque(mosque?._id, mosque));
      }
      if (admin?._id && admin) {
        dispatch(updateMosqueAdminDetails(admin?._id, admin));
      }
    } else {
      setErrors(validate.errors);
    }
  };

  return (
    <Box px="20px" py="28px">
      <Box display="flex">
        <Label fontSize="20px" fontWeight={700} cursor="pointer" onClick={() => setSelectedMosque(null)}>
        {t('Moskee informatie')}
        </Label>
        <Label fontSize="20px" fontWeight={700}>
          {" > "}
        </Label>
        <Label fontSize="20px" fontWeight={700} color="#7e7e7e">
        {t('Moskee bewerken')}
        </Label>
      </Box>
      <div className="row mt-4">
        <div className="col-lg-6">
          <label>
          {t('Naam Moskee')}<span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
            value={mosqueDetails.name}
            onChange={(e) => handleUpdate("name", e.target.value)}
            type="text"
            placeholder={t("Voer Naam Moskee In")}
            className="input-box"
          />
          {errors.name && <ErrorMessage>{JSON.stringify(errors.name)}</ErrorMessage>}
        </div>
        <div className="col-lg-6">
          <label>
          {t('Adres Moskee')}<span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
            disabled
            value={mosqueDetails.address}
            onChange={(e) => handleUpdate("address", e.target.value)}
            type="text"
            placeholder={t("Voer Adres Moskee In")}
            className="input-box"
          />
        </div>

        <div className="col-lg-6 pt-4">
          <label>
            {t('Postcode')}<span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
            disabled
            value={mosqueDetails.pincode}
            onChange={(e) => handleUpdate("pincode", e.target.value)}
            type="text"
            placeholder={t("Voer postcode in")}
            className="input-box"
          />
        </div>
        <div className="col-lg-6 pt-4">
          <label>
          {t('Land Moskee')}<span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
            disabled
            value={mosqueDetails.country}
            type="text"
            placeholder="Enter country"
            className="input-box"
          />
        </div>
        <div className="col-lg-6 pt-4 customDatePicker">
          <label>
          {t('Lidmaatschapsdatum')}<span style={{ color: "#FE1717" }}>*</span>
          </label>
          <br></br>
          <Input
              isDisabled
              value={getFormattedTime(mosqueDetails?.organisation_id?.membership_date ?? mosqueDetails?.organisation_id?.created_at,  "DD-MM-YYYY")}
              id="memberDate"
              className="input-box" />
        </div>
        <div className="col-lg-6 pt-4">
          <label>
          {t('Beheerdersnaam')}<span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
             value={mosqueDetails?.primary_user_id?.contact_name}
             onChange={(e) => handleUpdate("contact_name", e.target.value)}
            type="text"
            placeholder={t("Voer beheerdersnaam in")}
            className="input-box"
          />
         {errors.contact_name && <ErrorMessage>{JSON.stringify(errors.contact_name)}</ErrorMessage>}
        </div>
        <div className="col-lg-6 pt-4">
          <label>
          {t('Beheerderskosten')}<span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
             value={mosqueDetails?.admin_fee}
             onChange={(e) => handleUpdate("admin_fee", e.target.value)}
            type="text"
            placeholder={t("Voer beheerderskosten in")}
            className="input-box"
          />
        {errors.admin_fee && <ErrorMessage>{JSON.stringify(errors.admin_fee)}</ErrorMessage>}
        </div>
        <div className="col-lg-6 pt-4">
          <label>
            {t('Merchant-ID')}<span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
             value={mosqueDetails?.entity_merchant_id}
             onChange={(e) => handleUpdate("entity_merchant_id", e.target.value)}
            type="text"
            placeholder={t("Voer Merchant-ID in")}
            className="input-box"
          />
        {errors.entity_merchant_id && <ErrorMessage>{JSON.stringify(errors.entity_merchant_id)}</ErrorMessage>}
        </div>
        <div className="col-lg-6 pt-4">
          <label>
            {t('Merchant Key')}<span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
             value={mosqueDetails?.entity_merchant_key}
             onChange={(e) => handleUpdate("entity_merchant_key", e.target.value)}
            type="text"
            placeholder={t("Voer Merchant Key in")}
            className="input-box"
          />
        {errors.entity_merchant_key && <ErrorMessage>{JSON.stringify(errors.entity_merchant_key)}</ErrorMessage>}
        </div>
      
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          text={t("Annuleren")}
          width="15%"
          mt="4"
          height="60px"
          onClick={() => setSelectedMosque(null)}
        />
        <Button
          text={t("Save Details")}
          width="15%"
          mt="4"
          ml="4"
          height="60px"
          onClick={() => handleUpdateButton()}
          type={!isNext ? "disabled" : "primary"}
        />
      </div>
    </Box>
  );
};

export default EditMosque;
