import React, { useEffect, useState } from "react";
import Table from "../../../../../components/Table/Table";
import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import Searchbar from "../../../../../components/Searchbar";
import links from "../../../../../constants/industry.json";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import ProfileModal from "./ProfileModal";
import CongratulationModal from "./CongratulationModal";
import DisableMemberModal from "./DisableMemberModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMemberInfoList,
  updatePageForMemberInfoList,
} from "../../../adminAction";
import { setMemberDetailsVisible } from "../../../../Mosque/mosqueAction";
import PaginationV2 from "../../../../../components/Pagination/PaginationV2";
import { isMonthUpcoming } from "../../../../../util/Utility";
import truncate from "lodash/truncate";
import { background, fontWeight } from "styled-system";
import styled from "styled-components";
import CheckBoxFilterBy from "./CheckBoxFilterBy";
import CheckBoxSortBy from "./CheckBoxSortBy";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const BoxContainer = styled(Box)`
  background: #f5f5f5;
  width: 160px;
  padding: 10px;
  border-radius: 8px;
  margin-left: 10px;
  height: 38px;
`;

const TemplateTableData = [
  {
    heading: "S.No.",
  },
  {
    heading: "Naam Moskee",
  },
  {
    heading: "Naam Gebruiker",
  },
  {
    heading: "Jan",
  },
  {
    heading: "Feb",
  },
  {
    heading: "Mrt",
  },
  {
    heading: "Apr",
  },
  {
    heading: "Mei",
  },
  {
    heading: "Jun",
  },
  {
    heading: "Jul",
  },
  {
    heading: "Aug",
  },
  {
    heading: "Sep",
  },
  {
    heading: "Okt",
  },
  {
    heading: "Nov",
  },
  {
    heading: "Dec",
  },
];

const MemberTable = () => {
  const dispatch = useDispatch();

  const [value, setValue] = useState("");

  const bizIndustryLinks = links;

  const [filters, setFilters] = useState([]);
  const [sortBy, setSortBy] = useState("");

  const [show, setShow] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const {t} = useTranslation()

  const isFetching = useSelector(
    (state) => state.adminReducer.memberInfo?.isFetching
  );
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const memberList = useSelector(
    (state) => state.adminReducer.memberInfo?.memberInfoList
  );
  const memberInfoCount = useSelector(
    (state) => state.adminReducer.memberInfo?.memberInfoCount
  );
  const memberInfoPayments = useSelector(
    (state) => state.adminReducer.memberInfo?.memberInfoPayments
  );
  const currentPage = useSelector(
    (state) => state.adminReducer.memberInfo?.currentPage
  );
  const pageSize = useSelector(
    (state) => state.adminReducer.memberInfo?.pageSize
  );
  const customerList = useSelector(
    (state) => state.mosqueReducer.customerInfo?.customerInfoList
);
const [templateTableData, setTemplatetableData] = useState([
  {
    heading: "S.No.",
  },
  {
    heading: t("Naam Moskee"),
  },
  {
    heading: t("Naam Gebruiker"),
  },
  {
    heading: "Jan",
  },
  {
    heading: "Feb",
  },
  {
    heading: "Mrt",
  },
  {
    heading: "Apr",
  },
  {
    heading: "Mei",
  },
  {
    heading: "Jun",
  },
  {
    heading: "Jul",
  },
  {
    heading: "Aug",
  },
  {
    heading: "Sep",
  },
  {
    heading: "Okt",
  },
  {
    heading: "Nov",
  },
  {
    heading: "Dec",
  },
])
  useEffect(() => {
    dispatch(fetchMemberInfoList(
      {
        entity_id: activeEntity?._id,
        filters,
    }
    ));
  }, [dispatch, activeEntity, filters]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const filteredDetails = {};
      filteredDetails.qtext = value;
      dispatch(fetchMemberInfoList({entity_id: activeEntity?._id,filteredDetails}));
    }
  };

  const handleButton = (val) => {
    if (val === "") {
      const filteredDetails = {};
      dispatch(fetchMemberInfoList(filteredDetails));
    }
    setValue(val);
  };

  /*const renderPaymentStatus = (item, month) => {
    const filteredData = memberInfoPayments[item?.member_id]?.filter(
        (payment) => {
          return payment.payment_amount > 0 && dayjs(new Date(payment?.payment_due_date)).format("MMM").toLowerCase() === month
        }
    );
     if (filteredData?.length > 0) {
      return (
        <Box
          className={
            filteredData?.[0]?.payment_status !== "paid"
              ? "red-color"
              : "green-color"
          }
        ></Box>
      );
    } else {
      return <Box ml="7px">{"-"}</Box>;
    }
  };*/

  const renderPaymentStatus = (item, month) => {
    const filteredData = item?.payments?.filter(payment => payment.month === month);
        if (filteredData?.length > 0) {
        const status = filteredData[0]?.status;
        return (
            <Box
               className={status === "SUCCESS" ? "green-color" : "red-color"}
            ></Box>
            
        );
    } else {
        return <Box ml="7px">{"-"}</Box>;
    } 
}; 

  const showMemberDetails = (item, val = true) => {
    dispatch(setMemberDetailsVisible(item, val));
  };

  const renderMemberList = () => {
    console.log("members lists : ", memberList);
    return memberList?.map((item, index) => {
      return [
        `${(currentPage - 1) * pageSize + index + 1}.`,
        // <div><span>{item?.entity_id?.name ? truncate(item?.entity_id?.name, { length: 15 })  : 'NA'}</span></div>,
        <div>
          <h6
            style={{ fontWeight: "600" }}
            className="mosque-info-name"
            title={item?.subscriptions?.[0]?.entityId.name || "NA"}
            onMouseOver={(e) => (e.target.title = item?.subscriptions?.[0]?.entityId?.name)}
            onMouseOut={(e) =>
              (e.target.title = item?.subscriptions?.[0]?.entityId?.name
                ? truncate(item?.subscriptions?.[0]?.entityId?.name, { length: 15 })
                : "NA")
            }
          >
            {item?.entityId
              ? truncate(item?.subscriptions?.[0]?.entityId?.name, { length: 15 })
              : "NA"}
          </h6>
        </div>,
        <div
          //onClick={() => showMemberDetails(item)}
          style={{ display: "block" }}
          onClick={() => setSelectedId(item?._id)}
        >
          <h6
            style={{ cursor: "pointer", fontWeight: "600"}}
            className="member-info-name"
            title={item?.name || "NA"}
            onMouseOver={(e) =>
              (e.target.title = item?.name)
            }
            onMouseOut={(e) =>
              (e.target.title = item?.name
                ? truncate(item?.name, { length: 15 })
                : "NA")
            }
          >
            {/*{item?.subscriptions?.[0]?.status === "ACTIVE"
              ? truncate(item?.name, { length: 15 })
              }: "NA"}*/}
              {item?.name}
          </h6>
          <div>
            <small
              style={{
                color: "#4763E4",
                background: item?.paymentMode === "manual"
                    ? "#E3F2FD"
                    : item?.paymentMode === "automatic"
                        ? "#CEFFFF"
                            : "",
                borderRadius: "4px",
                padding: "2px 4px"
              }}
            >
              {item?.paymentMode  === "manual" ? "handmatig" : null}
              {item?.paymentMode  === "automatic" ? "automatic" : null}
            </small>
            <small
              style={{
                color: "#00AC4F",
                background:
                  item?.paymentMethod ? "#E5F7ED" : "",
                borderRadius: "4px",
                marginLeft: "8px",
                padding: "2px 4px",
              }}
            >
              {item?.paymentMethod}
            </small>
            <small style={{
              color: "#FF0000", 
              background:item?.subscriptions?.[0]?.status === "INACTIVE" ? "#FFCCCB" : "", 
              borderRadius: "4px",padding: "2px 4px"}}>
              { item?.subscriptions?.[0]?.status === "INACTIVE" ? "inactive" : null}</small>
          </div>
        </div>,
            renderPaymentStatus(item, 1),
            renderPaymentStatus(item, 2),
            renderPaymentStatus(item, 3),
            renderPaymentStatus(item, 4),
            renderPaymentStatus(item, 5),
            renderPaymentStatus(item, 6),
            renderPaymentStatus(item, 7),
            renderPaymentStatus(item, 8),
            renderPaymentStatus(item, 9),
            renderPaymentStatus(item, 10),
            renderPaymentStatus(item, 11),
            renderPaymentStatus(item, 12),
      ];
    });
  };

  const onPageChange = (page) => {
    dispatch(updatePageForMemberInfoList(page));
    dispatch(fetchMemberInfoList());
  };

  const handleFilterChange = ({ filters, pageNum }) => {
      dispatch(fetchMemberInfoList(filters, pageNum))
  };

  const onCheckboxChange = (selection) => {
    let updatedFilters = {};
    setFilters(selection);
    const payment_statuses_data = selection.filter(
      (item) => item["payment_status"]
    );
    const recurring_types_data = selection.filter(
      (item) => item["recurring_type"]
    );
    const payment_methods_data = selection.filter(
      (item) => item["payment_method"]
    );
    const customer_statuses_data = selection.filter(
      (item) => item["customer_status"]
    );
    if (!!payment_statuses_data?.length) {
      updatedFilters["payment_statuses"] = payment_statuses_data.map(
        (item) => item.payment_status
      );
      if (updatedFilters["payment_statuses"]?.includes("all")) {
        updatedFilters["payment_statuses"] = updatedFilters[
          "payment_statuses"
        ].filter((status) => status !== "all");
      }
    }
    if (!!recurring_types_data?.length) {
      updatedFilters["recurring_types"] = recurring_types_data.map(
        (item) => item.recurring_type
      );
    }
    if (!!payment_methods_data?.length) {
      updatedFilters["payment_methods"] = payment_methods_data.map(
        (item) => item.payment_method
      );
    }
    if (!!customer_statuses_data?.length) {
      updatedFilters["customer_statuses"] = customer_statuses_data.map(
        (item) => item.customer_status
      );
    }
    handleFilterChange({
      pageNum: 1,
      filters: updatedFilters,
    });
  };

  const handleSortBy = (selection) => {
    let updatedFilters = {};
    setSortBy(selection);
    if (selection) {
      updatedFilters.sortBy = selection;
    }
    handleFilterChange({
      pageNum: 1,
      filters: updatedFilters,
    });
  }

  return (
    <>
      <div className="table-bg member-info-table pb-4">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px="20px"
          py="28px"
        >
          <Label fontSize="20px"   fontWeight={700}>
          {t('Ledeninformatie')}
          </Label>
          <Box className="d-flex">
            <Searchbar
              value={value}
              setValue={(e) => handleButton(e.target.value)}
              placeholder={t("Zoeken")}
              fontSize="12px"
              border="0px solid rgba(0,0,0,.08)"
              searchIcon
              backgroundColor="#F5F5F5"
              color="#000"
              borderRadius="10px"
              width="252px"
              height="38px"
              paddingLeft="25px"
              position="relative"
              right="20px"
              inputProps={{
                type: "search",
                onKeyPress: handleKeyPress,
                height: "20px",
                marginBottom: "3px",
              }}
            />
            <BoxContainer>
              <CheckBoxFilterBy
                options={bizIndustryLinks}
                value={filters}
                onChange={onCheckboxChange}
              />
            </BoxContainer>
            <BoxContainer>
              <CheckBoxSortBy
                value={sortBy}
                handleSortBy={handleSortBy}
              />
            </BoxContainer>
          </Box>
        </Box>
        <Table
          gridTemplateColumnsHeader="5% 15% 17% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5%"
          gridTemplateColumnsBody="5% 15% 18% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5%"
          columns={templateTableData}
          data={renderMemberList()}
          fetching={isFetching}
        />
        <DefaultModal
          show={!!selectedId}
          onHide={() => setSelectedId(null)}
          className="profile-modal"
          style={{ paddingRight: "0px" }}
          centered={false}
          showHeader={false}
          size=""
          showFooter={false}
        >
          <ProfileModal
            selectedId={selectedId}
            onHide={() => setSelectedId(null)}
          />
        </DefaultModal>
        <DefaultModal
          onHide={() => setShow(false)}
          className="congrats-modal"
          style={{ paddingRight: "0px" }}
          centered={false}
          showHeader={false}
          size=""
          showFooter={false}
        >
          <CongratulationModal />
        </DefaultModal>
        <DefaultModal
          onHide={() => setShow(false)}
          className="congrats-modal"
          style={{ paddingRight: "0px" }}
          centered={false}
          showHeader={false}
          size=""
          showFooter={false}
        >
          <DisableMemberModal />
        </DefaultModal>
      </div>
      <PaginationV2
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={memberInfoCount}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default MemberTable;
