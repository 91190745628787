import React from "react";
import Icon from "../../../components/Icon/Icon";
import { useTranslation } from "react-i18next";

const DeleteComponent = ({ show, handleDelete, setshow, text}) => {
  const {t} = useTranslation()
  const handleOnClick = () => {
    setshow(!show);
    handleDelete();
  };

  return (
    <>
       <div className="d-flex justify-content-between">
                <div>

                </div>
                <div onClick={() => setshow(null, false)}>
                    <Icon
                        // style={{zIndex: 2000}}
                        iconName="blackCross"
                    />
                </div>
            </div>
    <div className="congrats-screen">
      <img src="https://ik.imagekit.io/rzlzf40okya/elixir/diseble.svg" />
      <h4>Verwijderen</h4>
      <p>{text}</p>

      <div className="d-flex justify-content-around mt-3">
        <button className="prev-bt" onClick={() => setshow(!show)}>
          {t('Annuleren')}
        </button>
        <button className="fill-bt" onClick={() => handleOnClick()}>
        {t('Verwijderen')}
        </button>
      </div>
    </div>
    </>
    
  );
};

export default DeleteComponent;
